import { Box } from "@mui/material";
import { formatDate, formatDateObject } from "../utils/dateTimePickerUtils";
export const DateCube = ({
    day,
    isAvailable,
    onDateSelect,
    isSelected,
    occupiedDays,
}) => {
    const styles = {
        date: {
            // textAlign: "center",
            flex: 1,
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: !day
                ? "transparent"
                : isAvailable
                ? isSelected
                    ? "rgb(0,60,113)"
                    : "#fff"
                : "#e1e3e5",
            color: isAvailable ? (isSelected ? "#fff" : "#000") : "#fff",
            fontWeight: isSelected ? "bold" : "normal",
            borderRadius: "4px",
            margin: "2px",
            border:
                !day || !isAvailable
                    ? "1px solid transparent"
                    : "1px solid #fff",
            cursor: day ? "pointer" : "not-allowed",
            transition: "600ms",
            "&:hover": {
                border: "1px solid #e1e3e5",
                backgroundColor: isSelected ? "rgb(0,60,113)" : "#e1e3e5",
                cursor: isAvailable ? "pointer" : "not-allowed",
            },
        },
    };
    if (!day) return <Box sx={styles.date}></Box>;
    const formattedDate = formatDateObject(day);

    const selectDate = () => {
        if (!isAvailable) return;
        onDateSelect(day);
    };

    return (
        <Box sx={styles.date} onClick={selectDate}>
            {day.getDate()}
        </Box>
    );
};
