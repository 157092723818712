
export const defaultQuestionOne = ''
export const defaultQuestionTwo = ''


export const defaultQuestionThree = 1


export const defaultQuestionFour = []

export const defaultQuestionFive = undefined

export const defaultQuestionSix = []


export const defaultQuestionSeven = undefined

export const defaultQuestionEight = {
  sex: "Keine Angabe",
  age: "Keine Angabe"
}

export const defaultQuestionNine = "Keine Angabe"




