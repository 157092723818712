import "./App.css";
import Quiz from "./pages/Quiz";
import Order from "./pages/Order";
import ContactNew from "./pages/ContactNew";
import { QuizContextProvider } from "./contexts/QuizContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ThankYou from "./pages/ThankYou";
import ThankYouOrder from "./pages/ThankYouOrder";
import ThankYouConfirmEmail from "./pages/ThankYouConfirmEmail";
import ThankYouFinishedConfirmEmail from "./pages/ThankYouFinishedConfirm";
import ThankYouQuestionnaireOnly from "./pages/ThankYouQuestionnaireOnly";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";
import "./App.css";
import ConfirmCancellation from "./pages/ConfirmCancellation";
import ConfirmRequestCancellation from "./pages/ConfirmRequestCancellation";

function App() {
    return (
        <div className="App" style={{ overflow: "visible" }}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={de}
            >
                <QuizContextProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Quiz />} />
                            <Route path="order" element={<Order />} />
                            <Route path="contact" element={<ContactNew />} />
                            <Route
                                path="privacy-policy"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path="terms-of-service"
                                element={<TermsOfService />}
                            />
                            <Route path="thank-you" element={<ThankYou />} />
                            <Route
                                path="confirm-cancellation"
                                element={<ConfirmCancellation />}
                            />
                            <Route
                                path="confirm-request-cancellation"
                                element={<ConfirmRequestCancellation />}
                            />
                            <Route
                                path="thank-you-questionnaire-only"
                                element={<ThankYouQuestionnaireOnly />}
                            />
                            <Route
                                path="thank-you-order"
                                element={<ThankYouOrder />}
                            />
                            <Route
                                path="thank-you-contact"
                                element={<ThankYouOrder />}
                            />
                            <Route
                                path="thank-you-confirm"
                                element={<ThankYouConfirmEmail />}
                            />
                            <Route
                                path="thank-you-finished-confirm"
                                element={<ThankYouFinishedConfirmEmail />}
                            />
                        </Routes>
                    </BrowserRouter>
                </QuizContextProvider>
            </LocalizationProvider>
        </div>
    );
}

export default App;
