import React, { useState } from "react";
import styles from "./QuestionTwo.module.css";
import { useQuiz } from "./../../contexts/QuizContext";
import SelectionButton from "../../components/SelectionButton";
import { questionStyles } from "../../question.styles";
import { Box, Typography } from "@mui/material";

// import { addStyle, removeStyle, getClassListString } from '../../utils/animationUtils'

export default function QuestionTwo() {
    const { questionTwo, setQuestionTwo } = useQuiz();

    const toggleQuestionTwo = (clickedLabel) => {
        setQuestionTwo(clickedLabel);
    };

    const labels = ["0-5 Monate", "6-12 Monate", "Seit mehr als 12 Monaten"];

    return (
        <Box sx={questionStyles.questionRootCentered}>
            <Typography sx={questionStyles.questionTitle}>
                Wie lange leiden Sie oder ein Angehöriger bereits an chronischen
                Schmerzen?
            </Typography>
            {labels.map((label, idx) => {
                return (
                    <SelectionButton
                        key={idx}
                        label={label}
                        selected={questionTwo.includes(label)}
                        handleClick={toggleQuestionTwo}
                    />
                );
            })}
        </Box>
    );
}
