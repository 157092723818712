import { useQuiz } from "./contexts/QuizContext";

export const questionStyles = {
    questionRootCentered: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
    },

    questionTitle: {
        fontSize: { xs: "1rem", md: "24px" },
        fontWeight: "bold",
        textAlign: "center",
        marginBlock: "1.5rem",
    },

    questionDescription: {
        fontSize: { xs: "1rem", md: "1.5rem", lg: "1.75rem" },
        marginBlock: "1.5rem",
        textAlign: "center",
        maxWidth: { xs: "80%", md: "100%" },
    },

    textJustified: {
        fontSize: { xs: "1rem", md: "1.5rem", lg: "1.75rem" },
        marginBlock: "1.5rem",
        textAlign: "justify",
        maxWidth: { xs: "95%", md: "60%", lg: "50%" },
    },
    textJustifiedSmall: {
        fontSize: { xs: ".8rem", sm: "1rem" },
        marginBlock: "1.5rem",
        textAlign: "justify",
        maxWidth: { xs: "95%", md: "60%", lg: "50%" },
    },
};
