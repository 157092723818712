import React from "react";
import styles from "./SelectionButton.module.css";
import { Button } from "@mui/material";

export default function SelectionButton({ label, selected, handleClick }) {
    const styles = {
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "56px",
            backgroundColor: "#fff",
            cursor: "pointer",
            textTransform: "none",
            fontSize: { xs: ".8rem", sm: "1rem" },
            fontWeight: "bold",
            marginBlock: "1rem",
            minHeight: "60px",
            transition: "600ms",
            width: "100%",
            maxWidth: "400px",
            height: { xs: "60px", sm: "80px" },

            "&:hover": {
                backgroundColor: "rgb(0,125,179)",
                color: "#fff",
            },
        },
    };
    return (
        <Button
            sx={{
                ...styles.root,
                backgroundColor: selected ? "rgb(0,125,179)" : "#fff",
                color: selected ? "#fff" : "#000",
            }}
            onClick={() => handleClick(label)}
        >
            {label}
        </Button>
    );
}
