import { Box, Typography, TextField } from "@mui/material";

export const TextFieldWithError = ({
    value,
    inputRef,
    handleChange,
    name,
    label,
    size,
    errorMsg,
}) => {
    const styles = {
        formField: {
            flex: 1,
        },
        textFieldWrapper: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minWidth: "250px",
        },
        error: {
            color: "red",
            fontSize: "0.75rem",
            mt: ".15rem",
            flex: 1,
        },
    };
    return (
        <Box sx={styles.textFieldWrapper}>
            <TextField
                sx={styles.formField}
                value={value}
                onChange={handleChange}
                name={name}
                label={label}
                size={size}
                inputRef={inputRef}
            />
            {errorMsg && <Typography sx={styles.error}>{errorMsg}</Typography>}
        </Box>
    );
};
