import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./PositiveResult.module.css";
import { useQuiz } from "../../contexts/QuizContext";
const prefixes = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
];
const questionThreeAnswers = [
    "Minimal",
    "Leichte",
    "Unbequem",
    "Mäßig",
    "Ablenkend",
    "Beunruhigend",
    "Intensiv",
    "Stark",
    "Unbeherrschbar",
    "Unaussprechlich",
];

const bodyPartMapping = {
    "front-torso": "Oberkörper (v)",
    "front-left-forearm": "Unterarm (vl)",
    "front-left-arm": "Oberarm (vl)",
    "front-left-shoulder": "Schulter (vl)",
    "front-neck": "Hals (v)",
    "front-head": "Kopf (v)",
    "front-right-shoulder": "Schulter (vr)",
    "front-right-arm": "Oberarm (vl)",
    "front-right-forearm": "Oberarm (vr)",
    "front-right-hand": "Hand (vr)",
    "front-stomach": "Bauch/Magen",
    "front-left-hand": "Hand (vl)",
    "front-left-thigh": "Oberschenkel (vl)",
    "front-right-thigh": "Oberschenkel (vr)",
    "front-right-knee": "Knie (vr)",
    "front-left-knee": "Knie (vl)",
    "front-left-leg": "Unterschenkel (vl)",
    "front-right-leg": "Unterschenkel (vr)",
    "front-right-foot": "Fuß (vr)",
    "front-left-foot": "Fuß (vl)",
    "back-left-foot": "Fuß (hl)",
    "back-left-leg": "Unterschenkel (hl)",
    "back-right-leg": "Unterschenkel (hr)",
    "back-right-foot": "Fuß (hr)",
    "back-right-thigh": "Oberschenkel (hr)",
    "back-left-thigh": "Oberschenkel (hl)",
    "back-right-buttocks": "Gesäß (r)",
    "back-left-buttocks": "Gesaäß (r)",
    "back-lower": "Unterer Rücken",
    "back-left-forearm": "Unterarm (hl)",
    "back-left-hand": "Hand (hl)",
    "back-left-ellbow": "Ellbogen (l)",
    "back-left-arm": "Oberarm (hl)",
    "back-upper": "Oberer Rücken",
    "back-left-shoulder": "Schulter (hl)",
    "back-neck": "Nacken",
    "back-head": "Kopf (h)",
    "back-right-shoulder": "Schulter (hr)",
    "back-right-arm": "Oberarm (hr)",
    "back-right-ellbow": "Ellbogen (r)",
    "back-right-forearm": "Unterarm (hr)",
    "back-right-hand": "Hand (hr)",
};

export default function PositiveResult() {
    const {
        setCurrentQuestion,
        questionOne,
        questionTwo,
        questionThree,
        questionFour,
        questionFive,
        questionSix,
        questionSeven,
        questionEight,
        questionNine,
    } = useQuiz();

    const [cleanedQuestions, setCleanedQuestions] = useState({});

    const cleanResults = () => {
        setCleanedQuestions({
            questionOne: questionOne.startsWith("Ich")
                ? "sich"
                : "einen Angehörigen",
            questionTwo: questionTwo.startsWith("6") ? "nicht" : "seit",
            questionThree: questionThree,
            questionFour: questionFour,
            questionFive: questionFive ? "eine" : "keine",
            questionSeven: questionSeven ? "" : "nicht",
        });
    };

    useEffect(() => {
        cleanResults();
    }, []);

    const styles = {
        normalText: {
            fontSize: { xs: ".8rem", sm: "1rem", md: "1.2rem" },
            fontWeight: "normal",
        },
        paddedText: {
            fontSize: { xs: ".8rem", sm: "1rem", md: "1.2rem" },
            fontWeight: "normal",
            paddingRight: "1rem",
        },
        answerContainer: {
            display: "flex",
            flexDirection: "column",
        },
        answerRow: {
            display: "flex",
            alignItems: "center",
            marginBlock: ".2rem",
        },
        questionNumber: {
            backgroundColor: "rgb(71, 132, 185)",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: { xs: "25px", sm: "35px", md: "40px" },
            minHeight: { xs: "25px", sm: "35px", md: "40px" },
            fontSize: { xs: ".9rem", sm: "1rem" },
            mr: { xs: ".8rem", md: "1rem" },
        },
        indentedAnswerRow: {
            ml: {
                xs: "calc(25px + 1rem)",
                sm: "calc(35px + 1rem)",
                md: "calc(40px + 1rem)",
            },
            display: "flex",
            marginBlock: ".33rem",
        },
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: "1rem", md: "2rem" },
                        fontWeight: "bold",
                        mt: { xs: "1rem", md: "0rem" },
                        paddingBlock: "1rem",
                    }}
                >
                    Ihre Antworten
                </Typography>
            </Box>
            <Box sx={styles.answerContainer}>
                <Box sx={styles.indentedAnswerRow}>
                    <Typography sx={styles.normalText}>
                        Um Änderungen vorzunehmen, klicken Sie einfach auf die
                        entsprechende<br></br> Seitenzahl unten zu Ihrer
                        Antwort.
                    </Typography>
                </Box>
                <Box sx={styles.answerRow}>
                    <Box sx={styles.questionNumber}>1</Box>
                    <Typography sx={styles.normalText}>
                        Sie haben den Schmerz-Test für{" "}
                        {cleanedQuestions.questionOne} ausgefüllt.
                    </Typography>
                </Box>
                <Box sx={styles.answerRow}>
                    <Box sx={styles.questionNumber}>2</Box>
                    <Typography sx={styles.normalText}>
                        Sie leiden {cleanedQuestions.questionTwo} länger als 6
                        Monaten an chronischen Schmerzen.
                    </Typography>
                </Box>
                <Box sx={styles.answerRow}>
                    <Box sx={styles.questionNumber}>3</Box>
                    <Typography sx={styles.normalText}>
                        Ihr durchschnittlicher Schmerzwert ist{" "}
                        {cleanedQuestions.questionThree}, (
                        {questionThreeAnswers[questionThree - 1]}).
                    </Typography>
                </Box>
                <Box sx={styles.answerRow}>
                    <Box sx={styles.questionNumber}>4</Box>
                    <Typography sx={styles.normalText}>
                        Sie haben die Schmerzen in folgenden Körperregionen
                        (v=vorne h=hinten l=links r=rechts):
                    </Typography>
                </Box>
                <Box sx={styles.indentedAnswerRow}>
                    <Typography sx={styles.normalText}>
                        {questionFour.map((bodyPart, idx) => {
                            return bodyPartMapping[bodyPart] !== ""
                                ? idx < questionFour.length - 1
                                    ? `${bodyPartMapping[bodyPart]}, `
                                    : `${bodyPartMapping[bodyPart]}`
                                : "";
                        })}
                    </Typography>
                </Box>
                <Box sx={styles.answerRow}>
                    <Box sx={styles.questionNumber}>5</Box>
                    <Typography sx={styles.normalText}>
                        Sie fühlen {cleanedQuestions.questionFive}{" "}
                        Erleichterung, wenn Sie sich auf etwas aufstützen, nach
                        vorne beugen oder sich setzen.
                    </Typography>
                </Box>
                {questionSix.length > 0 ? (
                    <>
                        <Box sx={styles.answerRow}>
                            <Box sx={styles.questionNumber}>6</Box>
                            <Typography sx={styles.normalText}>
                                Sie nutzten bisher folgende Behandlungsoptionen:
                            </Typography>
                        </Box>
                        <Box sx={styles.indentedAnswerRow}>
                            {questionSix.map((treatment, idx) => (
                                <Typography sx={styles.paddedText} key={idx}>
                                    {`${prefixes[idx]}) ${treatment}`}
                                </Typography>
                            ))}
                        </Box>
                    </>
                ) : (
                    <Box sx={styles.answerRow}>
                        <Box sx={styles.questionNumber}>6</Box>
                        <Typography sx={styles.normalText}>
                            Sie nutzten bisher keine der genannten
                            Behandlungsoptionen
                        </Typography>
                    </Box>
                )}

                <Box sx={styles.answerRow}>
                    <Box sx={styles.questionNumber}>7</Box>
                    <Typography sx={styles.normalText}>
                        Sie befinden sich zurzeit{" "}
                        {cleanedQuestions.questionSeven} in Behandlung bei einem
                        Schmerzspezialisten.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
