import { Box, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { max } from "date-fns";
import { useQuiz } from "../contexts/QuizContext";

export const BreadcrumButton = ({
    number,
    advanceViaBreamdcrumbs,
    isCompleted,
    isActive,
    isDisabled,
}) => {
    const { maxAllowedQuestion } = useQuiz();
    const handleNavigate = () => {
        if (maxAllowedQuestion < number) return;
        advanceViaBreamdcrumbs(number);
    };
    const styles = {
        breadcrumbButton: {
            border: "2px solid #fff",
            width: { xs: "15px", sm: "25px" },
            height: { xs: "15px", sm: "25px" },
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: { xs: "1rem", sm: "1.2rem" },
            fontWeight: "bold",
            padding: ".5rem",
            cursor: "pointer",
            transition: "600ms",
            backgroundColor: isCompleted ? "#fff" : "transparent",
            color: isCompleted ? "rgb(0,60,113)" : "#fff",
            transform: isActive ? "scale(1.2)" : "scale(.9)",
            "&:hover": {
                backgroundColor: "#fff",
                color: "rgb(0,60,113)",
            },
        },
        breadcrumbButtonDisabled: {
            border: "2px solid #fff",
            width: { xs: "15px", sm: "25px" },
            height: { xs: "15px", sm: "25px" },
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: { xs: "1rem", sm: "1.2rem" },
            fontWeight: "bold",
            padding: ".5rem",
            cursor: "pointer",
            transition: "600ms",
            backgroundColor: "transparent",
            color: "#e1e3e5",
            opacity: isActive ? "1" : ".4",
            transform: isActive ? "scale(1.2)" : "scale(.9)",
        },
    };
    return (
        <Box
            key={number}
            onClick={handleNavigate}
            sx={
                isDisabled
                    ? styles.breadcrumbButtonDisabled
                    : styles.breadcrumbButton
            }
        >
            {number}
        </Box>
    );
};
