import React from "react";
import styles from "./QuestionThree.module.css";
import SmileyButton from "../../components/SmileyButton";

import { useQuiz } from "../../contexts/QuizContext";
import { Typography, Box } from "@mui/material";
import { questionStyles } from "../../question.styles";

export default function QuestionThree() {
    const painLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const painLevelsMobile = [
        [1, 2, 3, 4, 5],
        [6, 7, 8, 9, 10],
    ];
    const { questionThree, setQuestionThree, isMobile } = useQuiz();

    const handleClick = (level) => {
        setQuestionThree(level);
    };

    const descriptions = [
        {
            code: "Minimal",
            description:
                "Meine Schmerzen sind kaum spürbar. Die meiste Zeit denke ich nicht darüber nach.",
        },
        {
            code: "Leichte",
            description:
                "Ich habe einen geringen Schmerzpegel. Ich nehme ihn nur wahr, wenn ich darauf achte oder gelegentlich ein stärkeres Stechen verspüre.",
        },
        {
            code: "Unbequem",
            description:
                "Mein Schmerz ist spürbar und lenkt mich ab, aber Ich habe mich daran gewöhnt und mich angepasst",
        },
        {
            code: "Mäßig",
            description:
                "Mein Schmerz stört oft meine Gedanken, aber ich kann die meisten meiner üblichen Aktivitäten fortsetzen.",
        },
        {
            code: "Ablenkend",
            description:
                "Ich bin die meiste Zeit über mit meinen Schmerzen beschäftigt. Wenn ich mich anstrenge, schaffe ich es trotzdem, zu arbeiten oder mich mit Freunden zu treffen.",
        },
        {
            code: "Beunruhigend",
            description:
                "Ich bin mir meiner Schmerzen ständig bewusst. Er beeinträchtigt die Arbeit und täglichen Aktivitäten, und ich habe Schwierigkeiten, mich zu konzentrieren.",
        },
        {
            code: "Intensiv",
            description:
                "Mein Schmerz beherrscht meine Gedanken und stört meinen Schlaf. Ich kann nicht mehr arbeiten oder alltäglichen Tätigkeiten nachgehen.",
        },
        {
            code: "Stark",
            description:
                "Meine Schmerzen sind so stark, dass es schwer ist, an etwas anderes zu denken. Ich bin körperlich nicht aktiv. Sprechen und Zuhören fällt mir schwer.",
        },
        {
            code: "Unbeherrschbar",
            description:
                "Ich kann an nichts anderes denken als an meine Schmerzen und kann mich kaum bewegen oder sprechen. Ich weine oder stöhne oft unkontrolliert.",
        },
        {
            code: "Unaussprechlich",
            description:
                "Ich bin bettlägerig und fühle mich manchmal wie im Delirium oder fühle mich so, als könnte ich aufgrund meiner Schmerzen das Bewusstsein verlieren.",
        },
    ];

    return (
        <Box sx={questionStyles.questionRootCentered}>
            <Typography sx={questionStyles.questionTitle}>
                Wie stark sind Ihre Schmerzen im Durchschnitt?
            </Typography>
            <Typography
                sx={{
                    ...questionStyles.questionDescription,
                    maxWidth: "450px",
                }}
            >
                Bewerten Sie auf einer Skala von 1 bis 10, wobei 1 für Minimale
                und 10 für Ihren stärksten Schmerz steht.
            </Typography>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                    }}
                >
                    {!isMobile && (
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            {painLevels.map((level, idx) => {
                                return (
                                    <SmileyButton
                                        key={idx}
                                        level={level}
                                        handleClick={() => handleClick(level)}
                                        selected={level === questionThree}
                                    />
                                );
                            })}
                        </Box>
                    )}
                    {isMobile &&
                        painLevelsMobile.map((group, idx) => {
                            return (
                                <Box
                                    key={idx}
                                    sx={{
                                        display: "flex",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    {group.map((level, idx) => {
                                        return (
                                            <SmileyButton
                                                key={idx}
                                                level={level}
                                                handleClick={() =>
                                                    handleClick(level)
                                                }
                                                selected={
                                                    level === questionThree
                                                }
                                            />
                                        );
                                    })}
                                </Box>
                            );
                        })}

                    {questionThree && (
                        <Typography sx={questionStyles.questionTitle}>
                            {descriptions[questionThree - 1].code}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
