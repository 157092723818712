import React from "react";
import styles from "./ThankYou.module.css";

export default function ThankYouFinishedConfirmEmail() {
    return (
        <div className={styles.root}>
            <h3>VIELEN DANK</h3>
            <div className={styles.section}>
                <h3>
                    Sie haben die Anmeldung für den Newsletter bereits
                    erfolgreich abgeschlossen.
                </h3>
                <p>
                    In Kürze erhalten Sie Ihren ersten Newsletter. Bitten sehen
                    Sie ebenfalls in Ihrem Spam-Ordner nach. Sollten Sie in der
                    Zwischenzeit Fragen haben können Sie gerne mit uns in
                    Kontakt treten.
                </p>
            </div>

            <div className={styles.section}>
                <p className={styles.bold}>Mo - Fr von 10 bis 16 Uhr</p>
                <p className={styles.bold}>
                    kostenfrei:{" "}
                    <a href="tel:0800-500-44-11-111">0800 500 44 111</a>
                </p>
                <p className={styles.bold}>
                    <a
                        href="mailto:kontakt@schmerz-experts.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        kontakt@schmerz-experts.de
                    </a>
                </p>
            </div>

            <div className={styles.section}>
                <p>Mit freundlichen Grüßen</p>
                <p>Ihr</p>
                <p>Schmerz-Spezialisten-Team</p>
            </div>
        </div>
    );
}
