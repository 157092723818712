import { Box, Typography } from "@mui/material";
export const TimeCube = ({ time, isAvailable, isSelected, onTimeSelect }) => {
    const styles = {
        time: {
            flex: 1,
            // maxWidth: { xs: 0, sm: "200px" },
            maxHeight: "20px",
            borderRadius: "4px",
            padding: ".25rem .5rem",
            display: "flex",
            justifyContent: "center",
            margin: ".2rem .5rem",
            border: "1px solid #f2f2f2",
            fontWeight: isSelected ? "bold" : "normal",
            color: isSelected ? "#fff" : "#000",
            cursor: "pointer",
            backgroundColor: isSelected ? "rgb(0, 60, 113)" : "#fff",
            transition: "600ms",
            visibility: isAvailable ? "visible" : "hidden",

            "&:hover": {
                border: "1px solid #e1e3e5",
                backgroundColor: isSelected ? "rgb(0,60,113)" : "#e1e3e5",
            },
        },
    };

    const selectDate = () => {
        if (!isAvailable) return;
        onTimeSelect(time);
    };

    return (
        <Box sx={styles.time} onClick={selectDate}>
            <Typography>{time}</Typography>
        </Box>
    );
};
