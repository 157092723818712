import React from "react";
import styles from "./QuestionSeven.module.css";
import YesNoPanel from "../../components/YesNoPanel";
import InfoIcon from "./../../assets/icons/InfoIcon.svg";
import { useQuiz } from "../../contexts/QuizContext";
import { Box, Typography } from "@mui/material";
import { questionStyles } from "../../question.styles";

export default function QuestionSeven() {
    const { questionSeven, setQuestionSeven } = useQuiz();
    const handleClick = (name) => {
        const bool = name === "yes" ? true : false;
        setQuestionSeven(bool);
    };
    return (
        <Box
            sx={{
                ...questionStyles.questionRootCentered,
                maxWidth: { sm: "70vw" },
            }}
        >
            <Typography sx={questionStyles.questionTitle}>
                Befinden Sie sich zurzeit bei einem Schmerzspezialisten in
                Behandlung?
            </Typography>
            <YesNoPanel selected={questionSeven} handleClick={handleClick} />
            <div className={styles.infobox}>
                <img src={InfoIcon} alt="info" />
                <p>
                    Schmerzmediziner sind Ärzte, die sich auf alle Arten von
                    Schmerzen spezialisiert haben. Sie verfügen über eine
                    jahrelange Weiterbildung in der Schmerzbehandlung und
                    konzentrieren sich auf die Behandlung von Patienten mit
                    starken Schmerzen.
                </p>
            </div>
        </Box>
    );
}
