import { Box, TextField } from "@mui/material";

import { AgreeTermsSection } from "./AgreeTermsSection";
import { TextFieldWithError } from "../components/TextFieldWithError";
import { SummarySection } from "./SummarySection";
export default function ContactViaEmailForm({
    fullName,
    fullNameRef,
    email,
    emailConfirm,
    phone,
    agreeTerms,
    agreeResults,
    agreeNewsletter,
    handleChange,
    handleSubmit,
    invalidFields,
    loading,
    contactOption,
    setinValidFields,
    quizCompleted,
}) {
    const styles = {
        formContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },
        formRow: {
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
        },
        formField: {
            flex: 1,
        },
        checkboxRow: {
            display: "flex",
            alignItems: "flex-start",
        },
    };
    return (
        <Box sx={styles.formContainer}>
            <Box sx={styles.formRow}>
                <TextFieldWithError
                    value={fullName}
                    handleChange={handleChange}
                    name="fullName"
                    label="Vorname, Nachname"
                    size="small"
                    inputRef={fullNameRef}
                    errorMsg={
                        invalidFields.includes("fullName") &&
                        "Bitte geben Sie Ihren vollständigen Namen an.\nVerwenden Sie ausschließlich Buchstaben."
                    }
                />
            </Box>
            <Box sx={styles.formRow}>
                <TextFieldWithError
                    sx={styles.formField}
                    value={email}
                    handleChange={handleChange}
                    name="email"
                    label="E-Mail"
                    size="small"
                    errorMsg={
                        invalidFields.includes("email") &&
                        "Bitte geben Sie eine gültige E-Mail-Adresse an."
                    }
                />
                <TextFieldWithError
                    value={emailConfirm}
                    handleChange={handleChange}
                    name="emailConfirm"
                    label="E-Mail bestätigen"
                    size="small"
                    errorMsg={
                        invalidFields.includes("emailConfirm") &&
                        "Diese E-Mail-Adresse stimmt nicht mit der zuvor eingegebenen überein. Bitte prüfen Sie Ihre Angaben."
                    }
                />
                <TextFieldWithError
                    value={phone}
                    handleChange={handleChange}
                    name="phone"
                    label="Telefon"
                    size="small"
                    errorMsg={
                        invalidFields.includes("phone") &&
                        "Bitte geben Sie Ihre Telefonnummer an.\nVerwenden Sie ausschließlich Ziffern."
                    }
                />
            </Box>
            <SummarySection contactOption={contactOption} />

            <AgreeTermsSection
                handleChange={handleChange}
                agreeNewsletter={agreeNewsletter}
                agreeResults={agreeResults}
                agreeTerms={agreeTerms}
                handleSubmit={handleSubmit}
                loading={loading}
                quizCompleted={quizCompleted}
            />
        </Box>
    );
}
