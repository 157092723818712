import React, { useState, useCallback } from "react";
import styles from "./QuestionFour.module.css";
import SelectablePeople from "../../components/SelectablePeople";
import PlusIconTwo from "./../../assets/icons/PlusIconTwo.svg";

import { useQuiz } from "../../contexts/QuizContext";
import { Box, Typography } from "@mui/material";
import { questionStyles } from "../../question.styles";

export default function QuestionFour() {
    const { questionFour, setQuestionFour } = useQuiz();
    const addBodyPart = (bodyPart) => {
        setQuestionFour((prevBodyParts) => prevBodyParts.concat([bodyPart]));
    };

    const removeBodyPart = (bodyPart) => {
        const remainingBodyParts = questionFour.filter(
            (part) => part !== bodyPart,
        );
        setQuestionFour([...remainingBodyParts]);
    };

    const toggleBodyPart = useCallback(
        (bodyPart) => {
            if (!questionFour.includes(bodyPart)) {
                addBodyPart(bodyPart);
            } else if (questionFour.includes(bodyPart)) {
                removeBodyPart(bodyPart);
            }
        },
        [questionFour],
    );

    const addMultipleBodyParts = async (bodyPartList) => {
        bodyPartList.forEach((part) => {
            addBodyPart(part);
        });
    };

    return (
        <Box sx={questionStyles.questionRootCentered}>
            <Typography sx={questionStyles.questionTitle}>
                Wo befinden sich Ihre Schmerzen?
            </Typography>
            <Typography sx={questionStyles.questionDescription}>
                Wählen Sie alle zutreffenden Bereiche aus.
            </Typography>
            <Box>
                <SelectablePeople
                    selectedBodyParts={questionFour}
                    toggleBodyPart={toggleBodyPart}
                />
            </Box>
        </Box>
    );
}
