import React, { createContext, useContext, useEffect, useState } from "react";

import {
    defaultQuestionOne,
    defaultQuestionTwo,
    defaultQuestionThree,
    defaultQuestionFour,
    defaultQuestionFive,
    defaultQuestionSix,
    defaultQuestionSeven,
    defaultQuestionEight,
    defaultQuestionNine,
} from "./defaultQuestions";
import { useMediaQuery } from "@mui/material";
import { set } from "date-fns";

const QuizContext = createContext();

export const QuizContextProvider = ({ children }) => {
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [questionOne, setQuestionOne] = useState(defaultQuestionOne);
    const [questionTwo, setQuestionTwo] = useState(defaultQuestionTwo);
    const [questionThree, setQuestionThree] = useState(defaultQuestionThree);
    const [questionFour, setQuestionFour] = useState(defaultQuestionFour);
    const [questionFive, setQuestionFive] = useState(defaultQuestionFive);
    const [questionSix, setQuestionSix] = useState(defaultQuestionSix);
    const [questionSeven, setQuestionSeven] = useState(defaultQuestionSeven);
    const [questionEight, setQuestionEight] = useState(defaultQuestionEight);
    const [questionNine, setQuestionNine] = useState(defaultQuestionNine);
    const [email, setEmail] = useState("");
    const [quizCompleted, setQuizCompleted] = useState("");
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const isMobile = useMediaQuery("(max-width: 768px)");

    let value = {
        currentQuestion,
        setCurrentQuestion,
        questionOne,
        setQuestionOne,
        questionTwo,
        setQuestionTwo,
        questionThree,
        setQuestionThree,
        questionFour,
        setQuestionFour,
        questionFive,
        setQuestionFive,
        questionSix,
        setQuestionSix,
        questionSeven,
        setQuestionSeven,
        questionEight,
        setQuestionEight,
        questionNine,
        setQuestionNine,
        email,
        setEmail,
        isMobile,
        quizCompleted,
        answeredQuestions,
        setAnsweredQuestions,
        setQuizCompleted,
    };

    return (
        <QuizContext.Provider value={value}>{children}</QuizContext.Provider>
    );
};

export const useQuiz = () => {
    return useContext(QuizContext);
};
