import React from "react";
import styles from "./NegativeResult.module.css";
import { useQuiz } from "../../contexts/QuizContext";
import { Box, Button, Typography } from "@mui/material";
import { questionStyles } from "../../question.styles";

export default function NegativeResult() {
    const { setCurrentQuestion, setAnsweredQuestions } = useQuiz();
    const styles = {
        navButtonResult: {
            border: "2px solid rgb(71, 132, 185)",
            fontWeight: "bold",
            color: "#fff",
            padding: ".5rem 1rem",
            textTransform: "none",
            fontSize: { xs: ".85rem", sm: "1.2rem" },

            "&:hover": {
                backgroundColor: "rgb(71, 132, 185)",
            },
        },
    };
    return (
        <Box>
            <Typography sx={questionStyles.questionTitle}>Ergebnis</Typography>
            <div className="question__answer">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={questionStyles.textJustified}>
                        Ausgehend von Ihren Antworten erscheint es, dass eine
                        Rückenmarkstimulation- oder Radiofrequenz-Therapie nicht
                        für Sie die geeignete Therapie-Option ist. Wir empfehlen
                        Ihren behandelnden Arzt zu kontaktieren.
                    </Typography>

                    <Button
                        sx={styles.navButtonResult}
                        onClick={() => {
                            setCurrentQuestion(2);
                            setAnsweredQuestions([1]);
                        }}
                    >
                        Zurück zum Quiz
                    </Button>
                    <Typography
                        sx={{
                            ...questionStyles.textJustifiedSmall,
                        }}
                    >
                        * Der Inhalt dieses Quiz dient nur zu
                        Informationszwecken und ist nicht für Produktwerbung
                        oder medizinische Diagnosen bestimmt. Die darin
                        enthaltenen Informationen stellen keine medizinische
                        oder rechtliche Beratung da. Die Synvie GmbH übernimmt
                        keinerlei Verantwortung oder Garantie für die
                        Vollständigkeit, Richtigkeit oder Aktualität dieser
                        Informationen. Die Synvie GmbH empfiehlt, dass Sie sich
                        mit all Ihren Fragen rund um Ihre Gesundheit an Ihren
                        Arzt wenden.
                    </Typography>
                </Box>
            </div>
        </Box>
    );
}
