import React from "react";
import styles from "./QuestionFive.module.css";
import LeanIcon from "./../../assets/icons/LeanIcon.svg";
import LeanSelectedIcon from "./../../assets/icons/LeanSelected.svg";
import SitIcon from "./../../assets/icons/SitIcon.svg";
import SitSelectedIcon from "./../../assets/icons/SitSelected.svg";
import BendIcon from "./../../assets/icons/BendIcon.svg";
import BendSelectedIcon from "./../../assets/icons/BendSelected.svg";
import YesNoPanel from "../../components/YesNoPanel";
import { useQuiz } from "../../contexts/QuizContext";
import { Typography, Box } from "@mui/material";
import { questionStyles } from "../../question.styles";

export default function QuestionFive() {
    const { questionFive, setQuestionFive } = useQuiz();

    const handleClick = (name) => {
        setQuestionFive(name === "yes");
    };
    return (
        <Box sx={questionStyles.questionRootCentered}>
            <Typography sx={questionStyles.questionTitle}>
                Empfinden Sie Erleichterung wenn Sie:
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: { xs: "column", lg: "row" },
                }}
            >
                <div className={styles.img__container}>
                    <div className={styles.label}>sich aufstützen,</div>
                    <img src={LeanIcon} alt="lean" />
                </div>

                <div className={styles.img__container}>
                    <div className={styles.label}>sich nach vorne lehnen,</div>
                    <img src={BendIcon} alt="bend" />
                </div>
                <div className={styles.img__container}>
                    <div className={styles.label}>sich setzen ?</div>
                    <img src={SitIcon} alt="sit" />
                </div>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: { lg: "4rem" },
                }}
            >
                <YesNoPanel selected={questionFive} handleClick={handleClick} />
            </Box>
        </Box>
    );
}
