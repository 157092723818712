import React from "react";
import styles from "./SmileyButton.module.css";
import SmileyLogo1 from "./../assets/icons/smileys/level1.svg";
import SmileyLogo2 from "./../assets/icons/smileys/level2.svg";
import SmileyLogo3 from "./../assets/icons/smileys/level3.svg";
import SmileyLogo4 from "./../assets/icons/smileys/level4.svg";
import SmileyLogo5 from "./../assets/icons/smileys/level5.svg";
import SmileyLogo6 from "./../assets/icons/smileys/level6.svg";
import SmileyLogo7 from "./../assets/icons/smileys/level7.svg";
import SmileyLogo8 from "./../assets/icons/smileys/level8.svg";
import SmileyLogo9 from "./../assets/icons/smileys/level9.svg";
import SmileyLogo10 from "./../assets/icons/smileys/level10.svg";
import { Box, Button, Typography } from "@mui/material";

export default function SmileyButton({ link, level, selected, handleClick }) {
    const logos = [
        SmileyLogo1,
        SmileyLogo2,
        SmileyLogo3,
        SmileyLogo4,
        SmileyLogo5,
        SmileyLogo6,
        SmileyLogo7,
        SmileyLogo8,
        SmileyLogo9,
        SmileyLogo10,
    ];
    const styles = {
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: selected ? "#fff" : "transparent",
            boxSizing: "border-box",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: selected ? "#fff" : "transparent",
            },
            padding: ".5rem",
            borderRadius: "4px",
        },
        number: {
            fontSize: { xs: "1rem", md: "1.5rem", lg: "1.75rem" },
            fontWeight: "700",
            color: selected ? "rgb(0,60,113)" : "#fff",
        },
    };

    return (
        <Box sx={styles.root} onClick={handleClick}>
            <Typography sx={styles.number}>{level}</Typography>
            <Box
                component="img"
                src={logos[level - 1]}
                alt="pain level"
                sx={{ width: { xs: "35px", sm: "45px", lg: "60px" } }}
            />
        </Box>
    );
}
