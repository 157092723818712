import React from "react";
import styles from "./ThankYou.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ConfirmRequestCancellation() {
    const navigate = useNavigate();
    return (
        <div className={styles.root}>
            <h3>VIELEN DANK.</h3>

            <p className={styles.bold}>
                Wir haben Ihre Stornierung erhalten. Wenn Sie einen neuen
                Telefontermin vereinbareren möchten, klicken Sie bitte hier
            </p>
            <Button
                sx={{
                    border: "2px solid #fff",
                    marginBlock: "2rem",
                    color: "#fff",
                    textTransform: "none",
                    fontSize: "1.5rem",
                }}
                onClick={() => navigate("/contact")}
            >
                Termin vereinbaren
            </Button>

            <div className={styles.section}>
                <p className={styles.bold}>Mo - Fr von 10 bis 16 Uhr</p>
                <p className={styles.bold}>
                    kostenfrei:{" "}
                    <a href="tel:0800-500-44-11-111">0800 500 44 111</a>
                </p>
                <p className={styles.bold}>
                    <a
                        href="mailto:kontakt@schmerz-experts.de"
                        target="_blank"
                        rel="noreferrer"
                    >
                        kontakt@schmerz-experts.de
                    </a>
                </p>
            </div>

            <div className={styles.section}>
                <p>Mit freundlichen Grüßen</p>
                <p>Ihr</p>
                <p>Schmerz-Spezialisten-Team</p>
            </div>
        </div>
    );
}
