import React from "react";
import styles from "./YesNoPanel.module.css";
import { Box, Button } from "@mui/material";

export default function YesNoPanel({ handleClick, selected }) {
    const styles = {
        yesButton: {
            margin: "1rem",
            width: "200px",
            height: { xs: "40px", md: "50px", lg: "70px" },
            fontSize: { xs: "1.2rem", md: "1.5rem" },
            textTransform: "none",
            fontWeight: "bold",
            border: "4px solid #fff",
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: selected ? "#fff" : "transparent",
                color: selected ? "rgb(0,60,113)" : "#fff",
            },
        },
        noButton: {
            margin: "1rem",
            width: "200px",
            height: { xs: "40px", md: "50px", lg: "70px" },
            fontSize: { xs: "1.2rem", md: "1.5rem" },
            textTransform: "none",
            fontWeight: "bold",
            border: "4px solid #fff",
            backgroundColor: "transparent",

            "&:hover": {
                backgroundColor: !selected ? "#fff" : "transparent",
                color: !selected ? "rgb(0,60,113)" : "#fff",
            },
        },
    };
    return (
        <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
            <Button
                onClick={() => handleClick("yes")}
                sx={{
                    ...styles.yesButton,
                    backgroundColor: selected === true ? "#fff" : "transparent",
                    color: selected ? "rgb(0,60,113)" : "#fff",
                }}
            >
                Ja
            </Button>
            <Button
                onClick={() => handleClick("no")}
                sx={{
                    ...styles.noButton,
                    backgroundColor:
                        selected === false ? "#fff" : "transparent",
                    color: selected === false ? "rgb(0,60,113)" : "#fff",
                }}
            >
                Nein
            </Button>
        </Box>
    );
}
