import { Box, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useQuiz } from "../contexts/QuizContext";

const styles = {
    navButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    iconButton: {
        backgroundColor: "#fff",
        width: "4rem",
        height: "4rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "rgb(0,60,113)",
        "&:hover": {
            opacity: ".7",
            backgroundColor: "#fff",
        },
    },

    navButtonLabel: {
        color: "#fff",
        textTransform: "none",
        fontWeight: 700,
        fontSize: { xs: "1rem", sm: "1.25rem" },
        textAlign: "center",
    },
};

export const NextButton = ({ label, handleNext, allowAdvance, isVisible }) => {
    const { isMobile } = useQuiz();
    return (
        <Box
            sx={{
                ...styles.navButton,

                visibility: isVisible ? "visible" : "hidden",
                display: isMobile ? "none" : "flex",
            }}
            disabled={!allowAdvance}
            onClick={handleNext}
        >
            <IconButton sx={styles.iconButton}>
                <ArrowForwardIosIcon
                    sx={{
                        fontSize: "2rem",
                        fontWeight: "bold",
                    }}
                />
            </IconButton>
            <Typography sx={styles.navButtonLabel}>{label}</Typography>
        </Box>
    );
};

export const BackButton = ({ label, handleBack, isVisible }) => {
    const { isMobile } = useQuiz();
    return (
        <Box
            sx={{
                ...styles.navButton,
                visibility: isVisible ? "visible" : "hidden",
                display: isMobile ? "none" : "flex",
            }}
            onClick={handleBack}
        >
            <IconButton sx={styles.iconButton}>
                <ArrowBackIosIcon
                    sx={{
                        fontSize: "2rem",
                        // fontWeight: "bold",
                        color: "rgb(0,60,113)",
                        transform: "translateX(4px)",
                    }}
                />
            </IconButton>
            <Typography sx={styles.navButtonLabel}>{label}</Typography>
        </Box>
    );
};
