import React from 'react'
import styles from './ThankYou.module.css'

export default function ThankYouQuestionnaireOnly() {
  return (
    <div className={styles.root}>
      <h3>VIELEN DANK</h3>
      <div className={styles.section}>
        <p>Sie erhalten in Kürze die Ergebnisse ihres Fragebogens via E-Mail.</p>
      </div>

      <div className={styles.section}>
        <p className={styles.bold}>Mo - Fr von 10 bis 16 Uhr</p>
        <p className={styles.bold}>kostenfrei: <a href="tel:0800-500-44-11-111">0800 500 44 111</a></p>
        <p className={styles.bold}><a href="mailto:kontakt@schmerz-experts.de" target="_blank">kontakt@schmerz-experts.de</a></p>
      </div>

      <div className={styles.section}>
        <p>Mit freundlichen Grüßen</p>
        <p>Ihr</p>
        <p>Schmerz-Spezialisten-Team</p>

      </div>
    </div>
  )
}
