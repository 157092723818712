import React, { useState, useEffect } from "react";
import { useQuiz } from "./../../contexts/QuizContext";
import SelectionButton from "./../../components/SelectionButton";
import { Box, Typography } from "@mui/material";
import { questionStyles } from "../../question.styles";

export default function QuestionOne() {
    const { questionOne, setQuestionOne } = useQuiz();

    const toggleLabel = (clickedLabel) => {
        setQuestionOne(clickedLabel);
    };

    const labels = [
        "Ich leide unter chronischen Schmerzen.",
        "Ein Angehöriger leidet unter chronischen Schmerzen.",
    ];

    return (
        <Box sx={questionStyles.questionRootCentered}>
            <Typography sx={questionStyles.questionTitle}>
                Welche Aussage beschreibt Ihre Situation am besten?
            </Typography>
            {labels.map((label, idx) => {
                return (
                    <SelectionButton
                        key={idx}
                        label={label}
                        selected={questionOne.includes(label)}
                        handleClick={toggleLabel}
                    />
                );
            })}
        </Box>
    );
}
