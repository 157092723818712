import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import QuestionFive from "../Questions/QuestionFive/QuestionFive";
import QuestionFour from "../Questions/QuestionFour/QuestionFour";
import QuestionOne from "../Questions/QuestionOne/QuestionOne";
import QuestionSeven from "../Questions/QuestionSeven/QuestionSeven";
import QuestionSix from "../Questions/QuestionSix/QuestionSix";
import QuestionThree from "../Questions/QuestionThree/QuestionThree";
import QuestionTwo from "../Questions/QuestionTwo/QuestionTwo";
import NegativeResult from "../Results/NegativeResult/NegativeResult";
import PositiveResult from "../Results/PositiveResult/PositiveResult";
import { useQuiz } from "../contexts/QuizContext";
import { DatePicker } from "@mui/x-date-pickers";
import ContactNew from "./ContactNew";

export default function Quiz() {
    const domain = "schmerz-spezialisten.de";

    const { currentQuestion, quizCompleted, setCurrentQuestion } = useQuiz();

    return (
        <div>
            {currentQuestion === 1 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <QuestionOne />
                </Layout>
            )}
            {currentQuestion === 2 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <QuestionTwo />
                </Layout>
            )}
            {currentQuestion === 3 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <QuestionThree />
                </Layout>
            )}
            {currentQuestion === 4 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <QuestionFour />
                </Layout>
            )}
            {currentQuestion === 5 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <QuestionFive />
                </Layout>
            )}
            {currentQuestion === 6 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <QuestionSix />
                </Layout>
            )}
            {currentQuestion === 7 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <QuestionSeven />
                </Layout>
            )}

            {quizCompleted && <ContactNew />}
            {currentQuestion === -1 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <NegativeResult />
                </Layout>
            )}
            {currentQuestion === -2 && (
                <Layout
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                >
                    <PositiveResult />
                </Layout>
            )}
        </div>
    );
}
