import React from "react";
// import TreamtmentButton from "../../components/TreamtmentButton";
import TreatmentButton from "../../components/TreatmentButton";
import { useQuiz } from "../../contexts/QuizContext";
import styles from "./QuestionSix.module.css";
import { Box, Typography } from "@mui/material";
import { questionStyles } from "../../question.styles";

export default function QuestionSix() {
    const { questionSix, setQuestionSix } = useQuiz();

    // const labels = [
    //   "Gymnastik und / oder Physiotherapie",
    //   "Akupunktur, Massage und Wirbelsäulenanpassung",
    //   "Entzündungshemmende Medikamente",
    //   "Schmerzmedikation wie z. B. Opioide, Schmerzmittel, Muskelrelaxantien, Anti-Epileptika, Antidepressiva",
    //   "Epidurale Steroid Injektionen oder Nervenblockaden",
    //   "Implantierbare Medikamentenpumpe",
    //   "Transkutane elektrische Nervenstimulation (TENS)",
    //   "Chirurgische Behandlung (z.B. Laminektomie, Wirbelsäulenversteifung)",
    //   "Rückenmarksstimulationstherapie",
    //   "Radiofrequenzablation",
    //   "Andere",
    //   "Nichts der oben genannten"
    // ]

    const labels = [
        "Schmerztabletten",
        "Chirurgische Operation",
        "Physiotherapie",
        "Nervendenervierung",
        "Keine Therapie",
        "Andere",
    ];

    const clearQuestionSixList = () => {
        setQuestionSix(["Keine Therapie"]);
    };

    const toggleTreatment = (clickedLabel) => {
        if (clickedLabel === "Keine Therapie") {
            clearQuestionSixList();
            return;
        }

        const isSelected = questionSix.includes(clickedLabel) ? true : false;
        if (!isSelected) {
            setQuestionSix((prevLabels) =>
                prevLabels
                    .filter((label) => label !== "Keine Therapie")
                    .concat([clickedLabel]),
            );
        } else if (isSelected) {
            const remainingSelection = questionSix.filter(
                (label) => label !== clickedLabel,
            );
            setQuestionSix([...remainingSelection]);
        }
    };

    return (
        <Box sx={questionStyles.questionRootCentered}>
            <Typography sx={questionStyles.questionTitle}>
                Wählen Sie alle bisher oder aktuell genutzten
                Behandlungsoptionen aus.
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: { xs: 0, sm: "1rem" },
                    maxWidth: "850px",
                }}
            >
                {labels.map((label, idx) => (
                    <TreatmentButton
                        selected={questionSix.includes(labels[idx])}
                        label={labels[idx]}
                        handleClick={toggleTreatment}
                        showIcons={true}
                    />
                ))}
            </Box>
        </Box>
    );
}
