import { useQuiz } from "./contexts/QuizContext";

export const styles = {
    root: {
        position: "relative",
        paddingBlock: { xs: 0, sm: "2rem", md: "3rem" },
        backgroundColor: "rgb(0, 60, 113)",
        color: "#fff",
        boxSizing: "border-box",
        minHeight: { xs: "100dvh", lg: "90vh" },
        display: "flex",
        flexDirection: "column",
        alignItems: { lg: "center" },
    },
    navContainer: {
        display: "flex",
    },

    navButtonResult: {
        border: "2px solid rgb(71, 132, 185)",
        fontWeight: "bold",
        color: "#fff",
        // padding: ".5rem 1rem",
        textTransform: "none",
        fontSize: { xs: ".85rem", sm: "1.2rem" },

        "&:hover": {
            backgroundColor: "rgb(71, 132, 185)",
        },
    },

    footer: {
        display: "flex",
        flexDirection: "column",

        width: {
            xs: "100vw",
            sm: "60vw",
            xl: "50vw",
        },
        alignItems: "space-between",
        gap: "1rem",
    },

    footerRow: {
        display: "flex",
        gap: ".25rem",
        justifyContent: "space-between",
        marginInline: { xs: "1rem", sm: 0 },
    },
};
