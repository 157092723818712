export const contactStyles = {
    container: {
        background: "#f2f2f2",
        display: "flex",
        flexDirection: "column",
        padding: { xs: "1rem", sm: "1rem", md: "2rem" },
        marginInline: { xs: "0rem", md: "4rem", lg: "10vw", xl: "20vw" },
        flex: 1,
    },
    titleContainer: {
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        mb: "2rem",
    },

    title: {
        fontSize: { xs: "1rem", md: "1.5rem" },
        fontWeight: "bold",
        color: "rgb(0, 60, 113)",
        ml: { xs: "0", sm: "1rem" },
    },
    title2: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        margin: 0,
        padding: 0,
        lineHeight: "1.35rem",
    },
    subtitle: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#000",
        // marginBlock: "2rem",
    },
    subtitle2: {
        fontSize: ".8rem",
        margin: 0,
        padding: 0,
        ml: { xs: "0", sm: "1rem" },
    },
    calendarTitle: {
        fontSize: ".9rem",
        fontWeight: "bold",
        marginBlock: "1rem",
    },

    buttonBox: {
        mb: "2rem",
        display: "flex",
        justifyContent: "space-between",
        gap: "1rem",
        flexWrap: "wrap",
    },
    contactOptionButton: {
        textTransform: "none",
        color: "white",
        flex: 1,
        fontWeight: "bold",
        borderRadius: "4px",
        paddingBlock: ".5rem",
        cursor: "pointer",
        backgroundColor: "rgb(71, 132, 185)",
        minWidth: "250px",
        "&:hover": {
            opacity: ".8",
            backgroundColor: "rgb(71, 132, 185)",
        },
    },
    contactOptionButtonSelected: {
        textTransform: "none",
        color: "white",
        flex: 1,
        fontWeight: "bold",
        borderRadius: "4px",
        paddingBlock: ".5rem",
        cursor: "pointer",
        backgroundColor: "rgb(0, 60, 113)",
        minWidth: "250px",
        "&:hover": {
            opacity: ".8",
            backgroundColor: "rgb(0, 60, 113)",
        },
    },
    timePickerContainer: {
        display: "flex",
        flexDirection: "column",
    },
    calendarContainer: {
        display: "flex",
        flexDirection: "column",
    },
    dateTimeContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
    },
    datePicker: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxWidth: "350px",
        padding: ".5rem 1rem",
        // border: "2px solid magenta",
    },
    timePicker: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxWidth: "350px",
        padding: ".5rem 1rem",
        // border: "2px solid magenta",
    },
    monthSelector: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "30px",
    },
    datePickerRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flex: 1,
    },
    timePickerColumn: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    timePickerRows: {
        display: "flex",
        // border: "2px solid orange",
        mt: "2rem",
        flex: 1,
        minWidth: "300px"
    },

    monthSelectionButton: {
        color: "#000",
        fontWeight: "bold",
    },
    timezone: {
        pt: ".75rem",
        paddingInline: ".5rem",
        fontSize: ".85rem",
        background: "#fff",
    },
    weekday: {
        textAlign: "center",
        flex: 1,
        height: "30px",
    },
    error: {
        color: "red",
        fontSize: "0.75rem",
        mt: ".15rem",
        flex: 1,
    },
    navButton: {
        border: "2px solid rgb(71, 132, 185)",
        fontWeight: "bold",
        color: "rgb(71, 132, 185)",
        padding: ".5rem 1rem",
        textTransform: "none",
        fontSize: { xs: "1rem", sm: "1.2rem" },

        "&:hover": {
            backgroundColor: "rgb(71, 132, 185)",
        },
    },
};
