import React from 'react'
import styles from './SelectablePeople.module.css'

export default function SelectablePeople({ selectedBodyParts, toggleBodyPart }) {
  return (
    <div className={styles.root}>
      <svg width="464.973" height="400" viewBox="0 0 464.973 586.805" aria-hidden="selected">
        <g transform="translate(-842.856 -403.195)">
          <g transform="translate(843.868 404.195)">
            <path onClick={() => toggleBodyPart("front-right-arm")} id="front-right-arm" className={selectedBodyParts.includes("front-right-arm") ? styles.selected : styles.normal} d="M3396.423,2679.425c-1.349-6.75-5.146-23.086-7.095-30.743-7.681,8.33-16.962,4.128-21.09,1.534a78.757,78.757,0,0,1,.813,19.206c-.473,5.885.508,13.04,1.3,18.789.206,1.518.4,2.945.554,4.235,2.188,9.5,5.78,22.746,7.113,26.262.045.116.093.233.139.35,2.451,5.208,5.574,8.852,9.221,10.694a12.244,12.244,0,0,0,10.25.384l0,0c5.727-2.186,8.423-8.422,8.492-19.927C3403.994,2703.369,3397.746,2686.062,3396.423,2679.425Z" transform="translate(-3225.036 -2526.383)"></path>
            <path onClick={() => toggleBodyPart("front-right-shoulder")} id="front-right-shoulder" className={selectedBodyParts.includes("front-right-shoulder") ? styles.selected : styles.normal} d="M3388.928,2646.7a25.487,25.487,0,0,0-16.015-17.184c-2.792-1.059-9.346-2.886-14.131-5.633q-.814-.465-1.609-.925c5.413,7.162,9.28,16.5,11.156,26.742,4.088,2.6,13.158,6.715,20.669-1.573.082-.089.16-.183.24-.274C3389.125,2647.419,3389.021,2647.031,3388.928,2646.7Z" transform="translate(-3225.349 -2524.943)"></path>
            <path onClick={() => toggleBodyPart("front-left-shoulder")} id="front-left-shoulder" className={selectedBodyParts.includes("front-left-shoulder") ? styles.selected : styles.normal} d="M3271.846,2629.948c-1.722.732-3.438,1.454-5.129,2.327-.241.124-.482.251-.722.384a27.936,27.936,0,0,0-2.85,1.773c-.468.334-.934.69-1.4,1.071a29.331,29.331,0,0,0-7.558,10.012c-.159.319-.315.648-.47.984q-.3.645-.593,1.329c.07.078.136.157.207.233,9.266,10.1,20.871,1.778,22.838.237.356-1.49.743-2.975,1.181-4.446a49.958,49.958,0,0,1,7.391-15.142c.84-1.171,1.719-2.315,2.662-3.414C3282.093,2626.791,3274.154,2628.967,3271.846,2629.948Z" transform="translate(-3225.399 -2524.877)"></path>
            <g transform="translate(51.217 495.621)">
              <path onClick={() => toggleBodyPart("front-left-foot")} id="front-left-foot" className={selectedBodyParts.includes("front-left-foot") ? styles.selected : styles.normal} d="M3307.231,3025.524l-.085.027c-1.487-4.973-5.253-8.108-9.826-8.183h-.164a11.037,11.037,0,0,0-9.63,6.248c-.125.4-.273.823-.449,1.253-1.542,3.773-6.362,12.956-7.284,14.41a21.232,21.232,0,0,0-2.29,5.406,7.856,7.856,0,0,0,0,2.831,17.877,17.877,0,0,1,.256,3.6,8.266,8.266,0,0,0,.855,3.662,2.481,2.481,0,0,0,2.748.657l-.184.744a2.035,2.035,0,0,0,1.226,2.144,3.467,3.467,0,0,0,2.536-.17c.385-.9-.592,1.857,1.708,2.487,1.374.375,2.907-.188,3.03-.6.218-.729-.411,1.131,1.475,1.7,1.518.451,3.152-.348,3.358-1.178.258-1.028-.468,1.286,2.009,1.715,2.343.4,3.8-.173,4.4-1.287a25.788,25.788,0,0,0,1.711-5.147c.085-.772,1.2-1.973,1.542-3.259s1.021-3.291.6-5.147a30.642,30.642,0,0,1,.069-10.463c.357-1.329,1.954-2.487,2.5-3.6a10.465,10.465,0,0,0,.514-5.92c-.142-.451-.376-1.131-.618-1.915" transform="translate(-3277.375 -3017.368)"></path>
            </g>
            <g transform="translate(52.578 395.161)">
              <path onClick={() => toggleBodyPart("front-left-leg")} id="front-left-leg" className={selectedBodyParts.includes("front-left-leg") ? styles.selected : styles.normal} d="M3313.485,2918.264a21.144,21.144,0,0,1-5.311,9.037,18.551,18.551,0,0,1-12.9,5.942,16.47,16.47,0,0,1-12.187-5.98,21.221,21.221,0,0,1-4.176-7.381c-.1,2.121-.193,4.222-.211,6.252-.092,9.935,2.673,25.39,3.875,30.708s6.837,28.3,6.837,33.965,1.711,13.553,0,20.415c-.692,2.791-2.222,5.147-1.708,7.549a18.481,18.481,0,0,1,.515,3.708,12.119,12.119,0,0,1,9.249-4.172,11.459,11.459,0,0,1,9.016,4.6c.3-1.793,1.425-3.841,1.658-5.595.254-1.888-.772-5.832-.943-9.006s0-19.472.427-25.045,4.53-25.99,5.129-35.426,1.2-15.868.941-22.215c-.087-2.16-.173-4.673-.206-7.356" transform="translate(-3278.698 -2918.263)"></path>
            </g>
            <g transform="translate(1.024 188.076)">
              <path onClick={() => toggleBodyPart("front-left-forearm")} id="front-left-forearm" className={selectedBodyParts.includes("front-left-forearm") ? styles.selected : styles.normal} d="M3256.262,2729.937a9.7,9.7,0,0,1-3.768.766,11.5,11.5,0,0,1-4.768-1.109c-4.113-1.872-6.714-5.152-7.944-10.025a28.827,28.827,0,0,1-.785-5.514c-.566,2.457-1.147,4.969-1.985,6.2a54.606,54.606,0,0,0-5.47,11.927c-1.881,6.353-4.922,27.874-4.306,36.266s.738,24.488.427,27.027c-.1.839-.188,1.738-.258,2.651,2.932-3.928,6.349-6.326,9.527-6.393a6.09,6.09,0,0,1,4.775,2.17c.184-2.468.6-5.9,1-8.13.561-3.113,4.633-13.594,6.813-20.12s6.58-20.45,8.524-25.923c.795-2.233,1.311-7.062,2.107-9.006.393-.956,1.556-3.666,2.718-6.42a16.564,16.564,0,0,1-6.6,5.636" transform="translate(-3227.156 -2714.055)"></path>
            </g>
            <g transform="translate(77.877 66.146)">
              <path onClick={() => toggleBodyPart("front-neck")} id="front-neck" className={selectedBodyParts.includes("front-neck") ? styles.selected : styles.normal} d="M3303.3,2615.531c8.349,7.439,16.985,10.53,25.676,9.181,10.016-1.556,17.256-8.835,18.3-10.654l.139.083c-3.688-5.062-4.963-7.609-4.869-12.573.063-3.2-.125-7.609.038-10.306-5.12,6.519-12.021,10.105-19.884,10.105-7.163,0-12.6-3.914-16.522-8.553a20.513,20.513,0,0,1,.555,2.879c.283,2.615.287,8.858.213,11.022a15.5,15.5,0,0,1-3.65,8.815" transform="translate(-3303.302 -2591.262)"></path>
            </g>
            <g transform="translate(53.26 372.591)">
              <path onClick={() => toggleBodyPart("front-left-knee")} id="front-left-knee" className={selectedBodyParts.includes("front-left-knee") ? styles.selected : styles.normal} d="M3295.108,2932.025c7.976,0,17.25-8.105,17.25-18.558,0-10.234-7.738-18.56-17.25-18.56-8.389,0-15.748,8.674-15.748,18.56s7.359,18.558,15.748,18.558" transform="translate(-3279.361 -2894.908)"></path>
            </g>
            <g transform="translate(67.293 0)">
              <path onClick={() => toggleBodyPart("front-head")} id="front-head" className={selectedBodyParts.includes("front-head") ? styles.selected : styles.normal} d="M3295.087,2574.819c.6,1.328,1.112,1.844,1.239,2.316s.685,1.63.983,2.359.734,1.962,1.5,2.1c.941.171,1.328-.514,1.626-1.243.343-.848.123-1.125.3-1.142a20.233,20.233,0,0,0,.421,3.344c2.637,6.254,9.557,19.083,21.875,19.083,10.027,0,18.406-6.32,23.188-17.4.811-2.461,1.266-4.422,1.359-4.7.171-.514.254-.087.237.1a2.963,2.963,0,0,0,.817,1.526,1.955,1.955,0,0,0,2.479-.229,6.847,6.847,0,0,0,.848-1.554c.142-.321.279-.655.412-.974.366-1.18.72-2.408,1.028-3.664.1-.417.206-.839.3-1.264a34.4,34.4,0,0,0,.406-8.991l-.04-.343c-.128-1-.471-2.711-1.771-2.7a1.149,1.149,0,0,0-.271.095,1.8,1.8,0,0,0-.556.476c-.078.063-.156.127-.227.2a3.369,3.369,0,0,0-.72.937,11.03,11.03,0,0,1,.093-1.727c.04-.332.083-.7.124-1.076a24.807,24.807,0,0,0,.182-3.374c-.085-1.928.286-11.664-3.59-18.641-4.737-8.525-13.733-12.818-23.934-12.809a27.934,27.934,0,0,0-22.793,12.122c-3.271,5-3.448,8.392-3.576,12.08s.856,12.995.81,13.338-.725-.429-.939-.729a1.691,1.691,0,0,0-2.222-.514,5.663,5.663,0,0,0-1.583,3.216,12.633,12.633,0,0,0,.128,3.6,35.014,35.014,0,0,0,1.881,6.175" transform="translate(-3293.009 -2525.528)"></path>
            </g>
            <g transform="translate(47.448 89.06)">
              <path onClick={() => toggleBodyPart("front-torso")} id="front-torso" className={selectedBodyParts.includes("front-torso") ? styles.selected : styles.normal} d="M3275.24,2676.382c.446,5.561-.349,12.109-1.1,17.651-.426,12-.391,23.369-.433,34.455,7.233-5.1,31.567-7.772,53.879-7.789h.185c9.318,0,37.493.56,46.258,7.044-.577-11.668-1.352-24.094-2.721-36.476-.089-.8-.157-1.628-.206-2.468-.785-5.731-1.7-12.714-1.228-18.594a74.779,74.779,0,0,0-4.153-31.544,52,52,0,0,0-11.255-18.611,27.871,27.871,0,0,1-5.47-5.1,32.423,32.423,0,0,1-19.178,10.977,27.684,27.684,0,0,1-4.246.33c-7.756,0-15.4-3.315-22.792-9.891a30.428,30.428,0,0,1-11.619,8.095c-.272.088-.624.2-1.034.317-5.077,4.752-9.106,11.6-11.744,20.021a84.274,84.274,0,0,0-3.138,31.582" transform="translate(-3273.709 -2614.952)"></path>
            </g>
            <g transform="translate(0 265.507)">
              <path onClick={() => toggleBodyPart("front-left-hand")} id="front-left-hand" className={selectedBodyParts.includes("front-left-hand") ? styles.selected : styles.normal} d="M3248.525,2821.467a65.488,65.488,0,0,1-3.1-7.012c-.267-.831-.884-5.45-1.115-7.756-.139-1.372-.682-2.453-1.3-5.376a61.362,61.362,0,0,1-1.285-6.418c-.865-1.755-2.311-3.475-4.676-3.437-3.479.072-7.015,3.527-9.733,8.039-.018.738-.022,1.463-.013,2.16.038,2.586-.537,5.846-.735,8.637s-.472,8.688-.4,10.29,1.073,13.919,1.5,15.139,2.025,3.163,3.05,5.116,1.466,3.284,1.967,4.149,2.049,2.873,2.673,3.836,1.428,2.943,1.882,2.892.469-.4.727-.658,1.114-.3,1.147-1-.047-.916.354-1.42a4.567,4.567,0,0,0,.871-2.921,8.651,8.651,0,0,0-1.248-3.885,18.5,18.5,0,0,1-1.542-2.468c-.458-.752.575.514,1.038,1.028s.7.56,1.17.469a2.461,2.461,0,0,0,.945-2.525,14.366,14.366,0,0,0-1.894-4.53c-.581-.568-.356-1.613-.506-2.937a6.631,6.631,0,0,0-1.035-2.528c-.09-.278.015-2.879-.1-3.96a10.783,10.783,0,0,0-.72-2.332c-.02-.171.988-1.9,1.16-2.379s.968-2.03,1.063-2.251c.17-.4.485-.48.72-.2a71.527,71.527,0,0,1,3.926,6.741,23.183,23.183,0,0,0,2.988,5.068c.728.838,1.486,1.43,2.478,1.095,1.461-.493,1.748-1.589,1.491-3.005a18.647,18.647,0,0,0-1.736-5.655" transform="translate(-3226.159 -2791.466)"></path>
            </g>
            <g transform="translate(41.641 261.497)">
              <path onClick={() => toggleBodyPart("front-left-thigh")} id="front-left-thigh" className={selectedBodyParts.includes("front-left-thigh") ? styles.selected : styles.normal} d="M3297.521,2797.192c-12.016-7.371-22.3-11.691-27.85-11.731a185.268,185.268,0,0,0-.339,46.025c.813,6.817,3.134,37.4,4.16,44.6s4.216,21.442,5.529,28.818a37.884,37.884,0,0,1,.48,4.186,21.164,21.164,0,0,1,3.908-6.6,16.456,16.456,0,0,1,12.184-5.98c8.946,0,16.465,6.669,18.443,15.6.141-2.16.351-4.3.659-6.35,1.189-7.985,3.589-17.157,4.018-21.615s.545-44.883,1-49.009c.987-9.056,1.679-15.758,2.011-18.876-3.118-4.4-13.041-12.228-24.2-19.077" transform="translate(-3268.062 -2785.461)"></path>
            </g>
            <g transform="translate(101.128 261.156)">
              <path onClick={() => toggleBodyPart("front-right-thigh")} id="front-right-thigh" className={selectedBodyParts.includes("front-right-thigh") ? styles.selected : styles.normal} d="M3351.369,2796.792c-11.788,6.927-22.1,14.661-25.454,19.052.249,3.765.7,13.078,1.116,21.523.309,6.272,2.222,42.373,2.651,47.262s2.478,16.641,2.734,19.3c.175,1.809.72,5.088.958,8.566a21.871,21.871,0,0,1,4.563-9.877c3.149-3.812,7.37-6,11.577-6,8.465,0,15.577,6.636,17.48,15.535.741-4.777,2.435-11.722,3.664-18.832,1.482-8.577,5.282-43.895,6.838-56.726,1.919-15.821,1.246-36.307-.04-51.466-6.073,1.241-15.374,5.369-26.082,11.664" transform="translate(-3325.915 -2785.129)"></path>
            </g>
            <g transform="translate(171.829 265.161)">
              <path onClick={() => toggleBodyPart("front-right-hand")} id="front-right-hand" className={selectedBodyParts.includes("front-right-hand") ? styles.selected : styles.normal} d="M3419.845,2820.089c-.18-2.249-.6-6.637-.594-7.553s-1.1-6.9-1.381-8.534c-.121-.692-.362-3.137-.579-6.007-1.838-4.1-4.4-6.581-7.232-6.842-2.673-.249-5.258,1.569-7.086,4.9a30.023,30.023,0,0,1-.972,6.967,26.64,26.64,0,0,0-.986,5.719,41.925,41.925,0,0,1-.388,5.363c-.285.782-1.439,4.137-2.224,6.258a39.527,39.527,0,0,0-1.94,5.477c-.14,1.131-.743,2.353-.079,3.435s1.679,2.072,2.764,1.291,3.136-4.645,4.076-6.581,2.562-6.178,3.155-6.478.531-.249.936.469a21.258,21.258,0,0,1,1.506,2.862c.182.644.262,1.109.644,1.365s-.243.213-.325.55a30.74,30.74,0,0,0-.351,4.045,4.842,4.842,0,0,0,.346,1.8c.19.245-.093.222-.343.55a14.731,14.731,0,0,0-.706,3.388c-.049.685-.2,1.19-.04,1.374s-.451-.092-.7.177a13.819,13.819,0,0,0-1.246,3.8c0,.747-.213,2.159.221,2.482s.8.8,1.216.548a18.315,18.315,0,0,0,2.009-2.492,19.784,19.784,0,0,1-1.486,3.161,8.883,8.883,0,0,0-1.409,3.681c-.095,1.311-.178,2.452.3,3s.917.757,1.15.72-.206.442-.074,1.028a1.369,1.369,0,0,0,.892,1.1c.446.146.1.981.88.465s3.527-5.3,3.931-6.135,4.729-9.287,5-9.841a140.646,140.646,0,0,0,1.125-15.512" transform="translate(-3396.078 -2791.13)"></path>
            </g>
            <g transform="translate(159.519 188.767)">
              <path onClick={() => toggleBodyPart("front-right-forearm")} id="front-right-forearm" className={selectedBodyParts.includes("front-right-forearm") ? styles.selected : styles.normal} d="M3403.048,2795.451c2.079-2.776,4.777-4.234,7.587-3.973,2.563.239,4.906,1.867,6.811,4.606-.1-1.888-.177-3.713-.18-5.142,0-4.7.2-23.943.061-29.913s-2.554-25.472-3.778-29.86-2.648-6.043-4.6-9.8a21.077,21.077,0,0,1-2-6.646c-1.014,8.176-4,12.91-9.194,14.894a15.174,15.174,0,0,1-5.421,1.04,13.647,13.647,0,0,1-6.2-1.5,16.081,16.081,0,0,1-2.017-1.214c.043.112.081.224.112.332.728,2.577.655,6.7,1.1,8.453s1.592,6.422,3.64,12.877,5.848,16.941,8.236,23.535a174.078,174.078,0,0,1,5.854,22.313" transform="translate(-3384.107 -2714.727)"></path>
            </g>
            <g transform="translate(107.624 393.455)">
              <path onClick={() => toggleBodyPart("front-right-leg")} id="front-right-leg" className={selectedBodyParts.includes("front-right-leg") ? styles.selected : styles.normal} d="M3338.652,3004.135c-.256,3.085-1.112,10.12-1.112,11.32a18.53,18.53,0,0,0,.855,5.233,15.845,15.845,0,0,1,.52,2.262,12.855,12.855,0,0,1,8.7-4.647,11.6,11.6,0,0,1,9.362,3.429,16.164,16.164,0,0,0,.605-4.083c-.127-1.459-.94-2.746-1.153-5.406s-1.587-17.546-.216-25.261c2.379-13.377,8.9-36.849,9.447-40.914,1.061-7.9,1.458-11.208,1.481-16.7.014-2.99-.429-7.257-.72-11.19a21.407,21.407,0,0,1-5.089,9.276c-3.513,3.79-7.978,5.964-12.252,5.964-4.207,0-8.431-2.186-11.576-6a22.032,22.032,0,0,1-4.724-10.82c-.254,3.142-.6,7.773-.543,11.8.085,5.662.514,20.33,1.8,29.678s2.906,15.867,3.848,24.188a152.487,152.487,0,0,1,.767,21.874" transform="translate(-3332.234 -2916.604)"></path>
            </g>
            <g transform="translate(43.345 195.007)">
              <path onClick={() => toggleBodyPart("front-stomach")} id="front-stomach" className={selectedBodyParts.includes("front-stomach") ? styles.selected : styles.normal} d="M3351.921,2797.125c13.76-8.088,22.075-10.95,26.8-11.881-.436-4.84-.925-9.065-1.412-12.323-1.639-11.06-2.09-27.4-2.967-45.789-4-4.721-21.969-7.741-46.326-7.741h-.233c-26.657.022-50.061,3.667-53.889,8.344-.085,16.582-.442,32.6-2.914,49.252-.332,2.234-.792,5.1-1.26,8.535,8.382.186,22.6,8.35,28.516,11.978,10.976,6.737,20.819,14.468,24.37,19.1l3.417-.051c3.557-4.584,14.014-12.448,25.9-19.434" transform="translate(-3269.718 -2719.392)"></path>
            </g>
            <g transform="translate(109.676 372.936)">
              <path onClick={() => toggleBodyPart("front-right-knee")} id="front-right-knee" className={selectedBodyParts.includes("front-right-knee") ? styles.selected : styles.normal} d="M3349.075,2932.36c7.523,0,16.268-8.105,16.268-18.56,0-10.232-7.3-18.558-16.268-18.558-7.908,0-14.847,8.671-14.847,18.558s6.938,18.56,14.847,18.56" transform="translate(-3334.228 -2895.243)"></path>
            </g>
            <g transform="translate(112.751 495.282)">
              <path onClick={() => toggleBodyPart("front-right-foot")} id="front-right-foot" className={selectedBodyParts.includes("front-right-foot") ? styles.selected : styles.normal} d="M3363.209,3035.377c-1.88-2.879-3.973-9.008-4.657-11.2-.1-.315-.206-.617-.309-.9a10.294,10.294,0,0,0-10.288-6.212,11.449,11.449,0,0,0-9.121,6.478c-.279.867-.761,1.865-1.131,2.912a19.286,19.286,0,0,0-.482,2.279c0,.112-.005.224,0,.334a10.91,10.91,0,0,0,1.977,6.176,10.716,10.716,0,0,1,1.786,6.777,65.506,65.506,0,0,0-.343,7.89c.172,1.63.77,4.634,1.369,5.233s.514,2.487,1.2,3.688,1.623,2.86,2.9,3.188a5.217,5.217,0,0,0,3.249,0,3.015,3.015,0,0,0,1.71-1.56c.085-.472.171.645.94,1.028a2.16,2.16,0,0,0,1.839.256c.81-.17,1.836-.814,1.921-1.457s.171,0,.728.17a2.562,2.562,0,0,0,1.838.173,2.761,2.761,0,0,0,1.793-1.542,2.64,2.64,0,0,0,0-1.332c0-.212.685.6,1.412.515s1.879-.429,2.18-1.074a2.6,2.6,0,0,0,.213-1.759c-.128-.559.085.085.855.085a2.659,2.659,0,0,0,2.093-.985,6.364,6.364,0,0,0,.641-3.085,18.433,18.433,0,0,1,.3-3.259,8.558,8.558,0,0,0,0-3.773,54.805,54.805,0,0,0-4.616-9.048" transform="translate(-3337.22 -3017.038)"></path>
            </g>
            <path onClick={() => toggleBodyPart("front-left-arm")} id="front-left-arm" className={selectedBodyParts.includes("front-left-arm") ? styles.selected : styles.normal} d="M3274.51,2675.431a85.915,85.915,0,0,1,1.741-26.479c-2.121,1.621-13.836,9.766-23.2-.266-.042-.046-.084-.094-.125-.139-.663,1.663-1.3,3.48-1.914,5.491-1.979,6.51-4.018,22.817-5.214,28.818-.939,4.707-3.767,18.212-5.4,25.319-1.138,12.275,1.183,18.865,7.506,21.741a8.586,8.586,0,0,0,7.172.323c4.092-1.71,7.865-6.734,11.706-15.651,1.782-5.047,4.514-12.892,6.176-18.492q.122-.913.254-1.878h0C3274,2688.469,3274.983,2681.317,3274.51,2675.431Z" transform="translate(-3225.765 -2526.385)"></path>
          </g>



          <g transform="translate(1104.863 404.937)">
            <g transform="translate(4.406 212.196)">
              <path onClick={() => toggleBodyPart("back-left-forearm")} id="back-left-forearm" className={selectedBodyParts.includes("back-left-forearm") ? styles.selected : styles.normal} d="M3534.995,2755.465a17.24,17.24,0,0,1-8.206,2.2,13.265,13.265,0,0,1-3.821-.542,14.171,14.171,0,0,1-8.545-7.891,18.084,18.084,0,0,1-1.628-7.491c-.131.395-.258.795-.377,1.2-1.336,4.582-4.381,22.591-4.6,28.841s-.437,31.053-.5,35.975l.038-.121a10.394,10.394,0,0,0-.163,1.962c2.563-4.451,5.948-6.964,9.606-7.018h.116a8.987,8.987,0,0,1,6.872,3.508c1.14-6,3.149-14.833,4.96-21.377,1.954-7.078,6.684-17.8,8.9-24.538,1.233-3.749,2.173-6.919,2.844-9.312a18.241,18.241,0,0,1-5.5,4.6" transform="translate(-3507.198 -2741.744)"></path>
            </g>
            <g transform="translate(55.542 503.728)">
              <path onClick={() => toggleBodyPart("back-left-foot")} id="back-left-foot" className={selectedBodyParts.includes("back-left-foot") ? styles.selected : styles.normal} d="M3585.438,3035l-.134.038c-1.713-5.908-6.241-9.46-11.517-9.029-4.549.366-10.274,4.1-11.43,13.406l-.218-.026c-.038.206-.848-.063-1.906,2.379-1.072,2.484-.267,4.88,1.165,8.23s2.056,7.725,5.726,9.974c3.058,1.871,5.012,2.239,9.217,2.335,3.571.081,7.489-2.276,8.68-4.848,1.226-2.643.6-8.113.36-9.071s3.041-1.975,3.041-5.655c0-2.885-2.1-6.265-2.861-7.183a.639.639,0,0,1-.124-.543" transform="translate(-3559.739 -3025.973)"></path>
            </g>
            <g transform="translate(0 272.412)">
              <path onClick={() => toggleBodyPart("back-left-hand")} id="back-left-hand" className={selectedBodyParts.includes("back-left-hand") ? styles.selected : styles.normal} d="M3527.727,2824.286c-.182-.947-.269-6.25-.758-8.312a61.421,61.421,0,0,0-2.748-6.861,3.587,3.587,0,0,1-.162-1.884c-1.692-2.647-4.03-4.151-6.508-4.113-1.79.027-6.326,1.005-9.693,9.488l-.285-.114a25.88,25.88,0,0,1-1.3,4.582c-.714,1.672-1.692,8.364-2.233,10.133a24.132,24.132,0,0,0-.944,4.358,13.789,13.789,0,0,0,.362,2.239,7.2,7.2,0,0,1-.229,2.085,85.117,85.117,0,0,0-.293,8.976c.458,1.554,6.506,14.122,6.8,14.537a1.671,1.671,0,0,0,1.311.637c.533.12,1.028-.245,1.457-1a6.7,6.7,0,0,0,.5-2.673l.037-.119a1.784,1.784,0,0,0,1.8-1.343,10.959,10.959,0,0,0-.2-4.207c-.245-1.028-1.169-2.76-1.159-3s.076-2.571.013-3.656a21.773,21.773,0,0,0-.53-2.427c.017-.3.478-2.313.747-4.037a31.865,31.865,0,0,0,.088-3.952c.091-.594,1.034-1.5,1.425-2.083s.822-2.115,1.085-2.461a6.681,6.681,0,0,1,3.26-1.807,2.433,2.433,0,0,1,2.233.72c.167,1,1.535,3.72,2.193,3.774.265.021-.1.451.056,1.2a13.856,13.856,0,0,0,3.539,5.462,2.342,2.342,0,0,0,3.836-1.417c.277-1.842-1.8-5.85-1.812-6.449a24.569,24.569,0,0,0-1.884-6.272" transform="translate(-3502.914 -2803.116)"></path>
            </g>
            <g transform="translate(47.572 164.528)">
              <path onClick={() => toggleBodyPart("back-lower")} id="back-lower" className={selectedBodyParts.includes("back-lower") ? styles.selected : styles.normal} d="M3605.284,2703.2c-14-.33-24.291-1.43-32.408-3.459-8.682-2.17-14.7-5.394-18.276-9.807-.185,3.43-.4,6.938-.685,10.162-.941,24.934-.55,49.775-1.926,72.742,23.084-13.9,86.83-14.512,108.152-.539-1.118-22.42-1.567-53.1-4.318-77.992-.155-1.39-.309-2.937-.452-4.558-8.522,11-30.6,13-49.3,13.452Z" transform="translate(-3551.988 -2689.75)"></path>
            </g>
            <g transform="translate(50.228 97.685)">
              <path onClick={() => toggleBodyPart("back-upper")} id="back-upper" className={selectedBodyParts.includes("back-upper") ? styles.selected : styles.normal} d="M3654.161,2672.028c-.213-13.757-.5-32.423-12.486-44.105a205.5,205.5,0,0,0-35.752-3.862,217.647,217.647,0,0,0-31.19,2.77c-11.412,4.611-18.048,22.807-19.73,54.16-.085,1.554-.166,3.291-.254,5.129-.056,1.181-.114,2.411-.177,3.664,7.206,11.825,30.751,13.893,50.557,14.364h.744c19.258-.471,42.191-2.557,49.078-14.5-.412-5.118-.712-10.436-.742-13.435.012.262.032.548.054.844-.047-1.59-.074-3.262-.1-5.028" transform="translate(-3554.572 -2624.061)"></path>
            </g>
            <g transform="translate(73.257 64.32)">
              <path onClick={() => toggleBodyPart("back-neck")} id="back-neck" className={selectedBodyParts.includes("back-neck") ? styles.selected : styles.normal} d="M3623.569,2602.9a31.1,31.1,0,0,1,1.151-6.908c-4.545-4.708-12.4-7.134-20.84-6.368-6.4.581-11.792,2.92-15.041,6.417.039.24.072.478.1.707a61.187,61.187,0,0,1,.537,7.947c-.245,7.157-6.952,15.882-12.5,20.208a226.413,226.413,0,0,1,28.88-2.375,204.073,204.073,0,0,1,33.158,3.343c-9.132-4.669-15.526-18.2-15.437-22.971" transform="translate(-3576.968 -2589.486)"></path>
            </g>
            <g transform="translate(51.117 387.874)">
              <path onClick={() => toggleBodyPart("back-left-leg")} id="back-left-leg" className={selectedBodyParts.includes("back-left-leg") ? styles.selected : styles.normal} d="M3591.9,2933.933c-2.527-13.45-7.65-20.629-15.605-21.9a12.97,12.97,0,0,0-10.3,2.943c-5.83,4.658-9.657,13.718-10.554,24.93-.025,10.411,2.817,26.265,4.056,31.766,1.252,5.567,5.011,26.58,5.011,32.328s.155,19.875-.281,22.332c-.283,1.6-.8,5.062-1.264,7.821a13.616,13.616,0,0,1,10.619-7.17c5.165-.417,9.686,2.365,12.171,7.249-.253-6.5-1.337-8.832-1.509-12.9-.146-3.359-.205-17.512.507-25.413.446-5.836,5.711-23.8,6.337-33.675s1.252-16.611.983-23.259c-.063-1.536-.123-3.243-.166-5.057" transform="translate(-3555.435 -2911.897)"></path>
            </g>
            <g transform="translate(69.715 0)">
              <path onClick={() => toggleBodyPart("back-head")} id="back-head" className={selectedBodyParts.includes("back-head") ? styles.selected : styles.normal} d="M3575.71,2574.881c.626,1.391,1.165,1.93,1.3,2.425s.72,1.706,1.028,2.467.768,2.057,1.565,2.2c.985.18,1.387-.539,1.7-1.3.361-.889.127-1.176.309-1.193.09,3.351,1.973,9.68,4.925,12.553a13.22,13.22,0,0,1,1.533,3.976c3.592-3.332,9.008-5.537,15.31-6.108,8.542-.772,16.565,1.578,21.561,6.2a26.714,26.714,0,0,1,1.6-4.248c2.776-4.758,3.937-11.493,4.113-12.03s.262-.091.249.11a2.149,2.149,0,0,0,.446,1.073,3.349,3.349,0,0,0,.206.276c1.249.157,1.964.157,2.674-.713.085-.115.171-.233.254-.355a17.68,17.68,0,0,0,2.527-8.22c.047-.475.087-.95.119-1.423.012-.159.021-.316.032-.476-.063-2.305,1.013-4.46-.372-6.5-.051-.13-.1-.255-.148-.381-.617-1.169-2.407-1.888-3.123-.452a10.516,10.516,0,0,0,.435-3.021c.009-.085.019-.173.027-.259a24.544,24.544,0,0,0,.163-3.275c-.089-2.021-1-11.423-4.386-16.674-3.817-5.927-14.019-14.009-24.7-14-11.861.009-19.705,6.175-23.245,11.585-3.423,5.23-3.959,7.639-4.094,11.5s.9,13.6.851,13.962-.761-.45-.985-.764a1.768,1.768,0,0,0-2.326-.539,5.932,5.932,0,0,0-1.654,3.367,13.214,13.214,0,0,0,.132,3.771,36.808,36.808,0,0,0,1.969,6.468" transform="translate(-3573.524 -2525.529)"></path>
            </g>
            <g transform="translate(162.16 194.045)">
              <path onClick={() => toggleBodyPart("back-right-ellbow")} id="back-right-ellbow" className={selectedBodyParts.includes("back-right-ellbow") ? styles.selected : styles.normal} d="M3684.127,2751.246c6.119-2.074,11.081-10.881,8.307-19.125a16.143,16.143,0,0,0-7.4-9.06,13.7,13.7,0,0,0-6.665-1.778,12.677,12.677,0,0,0-4.088.674c-6.426,2.175-9.763,10.929-7.138,18.73s10.566,12.737,16.99,10.562" transform="translate(-3666.239 -2721.283)"></path>
            </g>
            <g transform="translate(173.433 272.968)">
              <path onClick={() => toggleBodyPart("back-right-hand")} id="back-right-hand" className={selectedBodyParts.includes("back-right-hand") ? styles.selected : styles.normal} d="M3706.831,2835.166a7.164,7.164,0,0,1-.232-2.087,13.232,13.232,0,0,0,.361-2.234,23.584,23.584,0,0,0-.944-4.359c-.541-1.768-1.518-8.463-2.233-10.132a26.241,26.241,0,0,1-1.607-6.751c-.015-.315-.026-.6-.028-.873-2.383-4.061-5.639-6.429-9.1-6.478h-.113a8.319,8.319,0,0,0-6.963,4.151,3.683,3.683,0,0,1-.142,2,60.9,60.9,0,0,0-2.748,6.858c-.49,2.065-.577,7.366-.761,8.313a24.573,24.573,0,0,0-1.88,6.272c-.013.6-2.09,4.606-1.814,6.451a2.347,2.347,0,0,0,3.839,1.416,13.894,13.894,0,0,0,3.538-5.462c.157-.751-.206-1.183.056-1.2.658-.051,2.028-2.776,2.193-3.773a2.423,2.423,0,0,1,2.231-.72,6.689,6.689,0,0,1,3.261,1.807c.258.345.7,1.881,1.084,2.46s1.336,1.488,1.423,2.083a32.631,32.631,0,0,0,.087,3.951c.269,1.726.734,3.739.75,4.039a21.412,21.412,0,0,0-.531,2.426c-.062,1.082,0,3.417.012,3.655s-.911,1.966-1.156,3a10.918,10.918,0,0,0-.2,4.206,1.782,1.782,0,0,0,1.8,1.345l.036.117a6.719,6.719,0,0,0,.5,2.673c.429.759.925,1.12,1.458,1a1.678,1.678,0,0,0,1.311-.64c.292-.411,6.339-12.985,6.794-14.535a85.263,85.263,0,0,0-.289-8.977" transform="translate(-3678.606 -2802.25)"></path>
            </g>
            <g transform="translate(101.042 287.99)">
              <path onClick={() => toggleBodyPart("back-right-thigh")} id="back-right-thigh" className={selectedBodyParts.includes("back-right-thigh") ? styles.selected : styles.normal} d="M3654.3,2823.291c-6.2,4.485-14.58,6.833-22.784,6.833a36.867,36.867,0,0,1-15.835-3.354,21.624,21.624,0,0,1-10.291-9.713c.911,12.62,3.172,34.578,3.556,42.466.321,6.561,4.476,29.993,4.923,35.11s2.595,17.422,2.864,20.206c.249,2.584,1.214,8.021,1.116,12.956a27.8,27.8,0,0,1,8.146-11.938,14.7,14.7,0,0,1,11.7-3.313c6.737,1.073,11.7,6.061,14.842,14.863.009-.411.025-.8.054-1.163.357-4.791,2.624-13.53,4.175-22.511s3.383-31.586,5.011-45.019c1.668-13.752,1.467-30.866.579-45.363a23.035,23.035,0,0,1-8.052,9.942" transform="translate(-3605.393 -2813.351)"></path>
            </g>
            <g transform="translate(162.809 207.586)">
              <path onClick={() => toggleBodyPart("back-right-forearm")} id="back-right-forearm" className={selectedBodyParts.includes("back-right-forearm") ? styles.selected : styles.normal} d="M3685.6,2805.946a9.653,9.653,0,0,1,7.542-3.542c3.307.048,6.425,1.81,8.928,4.912-.032-5.745.435-29.471.225-35.565-.219-6.251-3.264-24.266-4.6-28.841a57.027,57.027,0,0,0-2.391-6.35,18.264,18.264,0,0,1-1.747,7.566,15.317,15.317,0,0,1-8.559,8.247,11.7,11.7,0,0,1-3.75.6,14.667,14.667,0,0,1-7.5-2.182,18.142,18.142,0,0,1-5.482-5.12c.076.395.152.75.227,1.029.488,1.828,1.747,6.705,3.964,13.438s6.944,17.461,8.9,24.538c1.8,6.5,3.188,15.273,4.238,21.265" transform="translate(-3668.272 -2736.56)"></path>
            </g>
            <g transform="translate(42.261 236.996)">
              <path onClick={() => toggleBodyPart("back-left-buttocks")} id="back-left-buttocks" className={selectedBodyParts.includes("back-left-buttocks") ? styles.selected : styles.normal} d="M3551.678,2773.458c-.514,7.907-1.254,15.584-2.347,22.95-.577,3.9-1.646,9.618-2.508,16.87a21.136,21.136,0,0,0,7.695,9.871c10.3,7.506,27.411,9.06,38.971,3.545,4.272-2.038,9.32-5.816,10.955-12.566.074-.822.148-1.69.228-2.629a4.131,4.131,0,0,1,.247-1.187v-47.957h-.076c-23.391.359-43.289,4.563-53.16,11.1" transform="translate(-3546.823 -2762.353)"></path>
            </g>
            <g transform="translate(41.372 289.767)">
              <path onClick={() => toggleBodyPart("back-left-thigh")} id="back-left-thigh" className={selectedBodyParts.includes("back-left-thigh") ? styles.selected : styles.normal} d="M3594.709,2826.568a38.938,38.938,0,0,1-16.6,3.448c-8.771,0-17.753-2.468-24.19-7.162a24.115,24.115,0,0,1-7.032-7.776,175.015,175.015,0,0,0,.273,38.5c.851,7.135,1.853,24.78,2.923,32.328s4.416,22.45,5.788,30.172a68.829,68.829,0,0,1,.471,14.558c1.966-6.185,5.038-11.145,8.97-14.286a14.706,14.706,0,0,1,11.7-3.314c7.069,1.131,12.188,6.565,15.292,16.2a96.952,96.952,0,0,1,.912-12.264c1.247-8.359,3.761-17.958,4.207-22.629s4.537-51.35,4.923-55.676c.963-10.818,1.506-15.784,1.969-20.3a22.284,22.284,0,0,1-9.608,8.2" transform="translate(-3545.959 -2815.079)"></path>
            </g>
            <g transform="translate(113.437 387.874)">
              <path onClick={() => toggleBodyPart("back-right-leg")} id="back-right-leg" className={selectedBodyParts.includes("back-right-leg") ? styles.selected : styles.normal} d="M3637.869,2912.038a12.97,12.97,0,0,0-10.3,2.943c-4.888,3.907-8.366,10.906-9.9,19.671-.146,2.417-.252,5.038-.213,7.45.089,5.926,1.432,20.564,2.776,30.351s3.311,16.793,4.293,25.505c.72,7.9,1.489,20.256,1.343,23.617-.171,3.968-1.2,6.284-1.491,12.426a13.5,13.5,0,0,1,13.134-6.243,13.971,13.971,0,0,1,9.411,5.152c-.247-1.573-.568-3.6-1.038-6.337-1.194-6.944-.655-18.138,0-28.5s5.988-33.221,6.561-37.477c1.114-8.274,1.527-11.736,1.552-17.48.012-2.57-.3-6.039-.579-9.475-2.548-13.264-7.651-20.349-15.551-21.606" transform="translate(-3617.449 -2911.897)"></path>
            </g>
            <g transform="translate(101.042 236.996)">
              <path onClick={() => toggleBodyPart("back-right-buttocks")} id="back-right-buttocks" className={selectedBodyParts.includes("back-right-buttocks") ? styles.selected : styles.normal} d="M3616.768,2827.167c10.854,5.217,27.018,3.762,36.8-3.315a20.217,20.217,0,0,0,8.722-14.01c-.539-7.066-1.22-13.231-1.876-17.674-.728-4.907-1.233-11.5-1.639-19.168-9.151-6.766-29.377-10.8-53.383-10.642v48.382h.037c0,.07-.012.137-.016.206.489,9.128,6.444,13.858,11.356,16.219" transform="translate(-3605.393 -2762.354)"></path>
            </g>
            <g transform="translate(117.866 503.699)">
              <path onClick={() => toggleBodyPart("back-right-foot")} id="back-right-foot" className={selectedBodyParts.includes("back-right-foot") ? styles.selected : styles.normal} d="M3648.038,3038.882c0-.146.085-1.078.049-2.21-.806-4.7-3.987-9.891-10.764-10.625a11.609,11.609,0,0,0-12.64,8.15c.092.389.123.628-.063.854-.762.925-2.865,4.3-2.865,7.184,0,3.68,3.282,4.7,3.044,5.655s-.867,6.429.357,9.07c1.194,2.571,5.109,4.93,8.68,4.849,4.21-.1,6.162-.465,9.218-2.334,3.669-2.244,4.3-6.615,5.729-9.974s2.236-5.747,1.163-8.232c-1.081-2.5-1.908-2.159-1.908-2.393" transform="translate(-3621.755 -3025.946)"></path>
            </g>
            <path onClick={() => toggleBodyPart("back-right-shoulder")} id="back-right-shoulder" className={selectedBodyParts.includes("back-right-shoulder") ? styles.selected : styles.normal} d="M3673.622,2648.725a36.433,36.433,0,0,0-1.661-3.926c-2.739-5.548-7.32-11.219-14.354-13.887a26.21,26.21,0,0,0-3.929-.936c-.341-.064-.695-.126-1.059-.19-3.636-.638-8.292-1.32-11.856-2.164l-.013.067a37.384,37.384,0,0,1,8.139,13,54.111,54.111,0,0,1,2.375,8.241C3653.812,2650.854,3664.74,2658.092,3673.622,2648.725Z" transform="translate(-3504.797 -2525.551)"></path>
            <path onClick={() => toggleBodyPart("back-left-shoulder")} id="back-left-shoulder" className={selectedBodyParts.includes("back-left-shoulder") ? styles.selected : styles.normal} d="M3570,2627.515c-3.98,1.078-9.828,2.6-13.8,3.829a31.426,31.426,0,0,0-3.712,1.321c-.924.446-1.79.908-2.611,1.387-.158.091-.312.186-.468.28a28.067,28.067,0,0,0-5.223,4.008,28.389,28.389,0,0,0-6.264,9.635l.022.024c7.206,7.856,15.824,4.566,20.229,1.992.634-2.528,1.333-4.875,2.1-7.024a39.983,39.983,0,0,1,6.886-12.633A23.428,23.428,0,0,1,3570,2627.515Z" transform="translate(-3503.37 -2525.555)"></path>
            <path onClick={() => toggleBodyPart("back-left-arm")} id="back-left-arm" className={selectedBodyParts.includes("back-left-arm") ? styles.selected : styles.normal} d="M3537.866,2648.682s-6.554,22.151-9.689,36.3-10.644,46.117-10.644,46.117a15.108,15.108,0,0,1,13.779-5.723c9.165,1,12.257,10.661,12.719,12.572,0,0,8.269-24.621,8.977-32.459,0,0,1.35-14.619,1.942-30.154s3.475-24.645,3.475-24.645-6.2,3.884-12.359,2.7S3537.866,2648.682,3537.866,2648.682Z" transform="translate(-3503.945 -2527.124)"></path>
            <path onClick={() => toggleBodyPart("back-right-arm")} id="back-right-arm" className={selectedBodyParts.includes("back-right-arm") ? styles.selected : styles.normal} d="M3651.37,2649.7a120.922,120.922,0,0,1,1.492,19.524c-.221,9.257,1.168,23.808,1.885,29.194,0,0,6.118,24.588,10.289,36.175,0,0,.561-8.215,8.263-11.85s15.183.783,18.712,5.987c0,0-7.328-31.2-10.556-45.91s-6.515-29.545-7.645-33.263a15.27,15.27,0,0,1-8.687,4.521C3659.862,2654.739,3653.114,2651.268,3651.37,2649.7Z" transform="translate(-3504.498 -2527.099)"></path>
            <path onClick={() => toggleBodyPart("back-left-ellbow")} id="back-left-ellbow" className={selectedBodyParts.includes("back-left-ellbow") ? styles.selected : styles.normal} d="M3532.7,2725.623a10.523,10.523,0,0,0-3.026-.436,13.264,13.264,0,0,0-6.975,2.11,16.836,16.836,0,0,0-6.281,21.018,12.4,12.4,0,0,0,7.433,6.921,14.3,14.3,0,0,0,10.655-1.518,15.554,15.554,0,0,0,7.595-9.13,15.932,15.932,0,0,0-.945-11.716,13.906,13.906,0,0,0-8.457-7.251" transform="translate(-3504.019 -2527.128)"></path>
          </g>
        </g>
      </svg>
      <div className={styles.sides}>
        <p>Vorne</p>
        <p>Hinten</p>
      </div>

    </div>
  )
}
